<template>
  <UserBaseModal
    :visible="$attrs"
    :okDisabled="false"
    :okText="$t('accountProfiles.save')"
    :loading="false"
    :title="$t('accountProfiles.editPass')"
    @cancel="handleCancel"
    @ok="handleOk">
    <a-form
      autocomplete="off"
      has-feedback
      ref="formRef"
      :model="formData"
      :rules="rules"
      name="passwordForm">
      <a-form-item name="oldPass">
        <a-input-password
          v-model:value="formData.oldPass"
          :placeholder="$t('accountProfiles.oldPass')" />
      </a-form-item>
      <div class="passRule">{{$t('accountProfiles.newPassWithRule')}}</div>
      <a-form-item name="newPass">
        <a-input-password
          v-model:value="formData.newPass"
          :placeholder="$t('accountProfiles.newPass')"
          @change="newPassChange" />
      </a-form-item>
      <a-form-item name="confirmPass">
        <a-input-password
          v-model:value="formData.confirmPass"
          :placeholder="$t('accountProfiles.enterNewPassAgain')" />
      </a-form-item>
    </a-form>
  </UserBaseModal>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import UserBaseModal from '@/components/user/modal/UserBaseModal'
import { message } from 'ant-design-vue'
import api from '@/services/api'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'PasswordModal',
  components: {
    UserBaseModal
  },
  props: {},
  setup (props, context) {
    const formRef = ref(null)
    const { t } = useI18n()

    const oldPassValidator = async (rule, value) => {
      console.log(rule, value)
      if (!value || value === '') {
        return Promise.reject(new Error(t('accountProfiles.enterOldPass')))
      } else {
        return Promise.resolve()
      }
    }

    const newPassValidator = async (rule, value) => {
      console.log(rule, value)
      const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,20}$/
      if (!value || value === '') {
        return Promise.reject(new Error(t('accountProfiles.enterNewPass')))
      } else if (value && !reg.test(value)) {
        return Promise.reject(new Error(t('accountProfiles.passRule')))
      } else {
        return Promise.resolve()
      }
    }

    const confirmPassValidator = async (rule, value) => {
      console.log(rule, value)
      if (!value || value === '') {
        return Promise.reject(new Error(''))
      } else if (state.formData.newPass !== value) {
        return Promise.reject(new Error(t('accountProfiles.passDifferent')))
      } else {
        return Promise.resolve()
      }
    }

    const state = reactive({
      formData: {},
      rules: {
        oldPass: [
          { required: true, validator: oldPassValidator, trigger: 'blur' }
        ],
        newPass: [
          { required: true, validator: newPassValidator, trigger: 'blur' }
        ],
        confirmPass: [
          { required: true, validator: confirmPassValidator, trigger: 'blur' }
        ]
      }
    })
    onMounted(async () => {

    })

    const newPassChange = async () => {
      formRef.value.validate(['newPass'])
    }

    const handleCancel = () => {
      state.formData = {}
      context.emit('cancel')
      context.emit('update:visible', false)
    }

    const handleOk = async () => {
      try {
        await formRef.value.validate()
        // context.emit('ok')
        updatePassword()
      } catch (error) {
        console.error(error)
      }
      context.emit('ok')
    }

    const updatePassword = async () => {
      try {
        const res = await api.user.updateUserPass({
          password: state.formData.oldPass,
          newPassword: state.formData.newPass
        })
        if (res.code === 200) {
          formRef.value.resetFields()
          message.success(t('accountProfiles.editPassSuccess'))
          context.emit('update:visible', false)
        }
      } catch (error) {
        console.error(error)
      }
    }

    return {
      ...toRefs(state),
      handleCancel,
      handleOk,
      formRef,
      newPassChange
    }
  }
})
</script>
<style lang='less' scoped>
.passRule {
  color: rgba(154, 154, 154, 100);
  margin-bottom: 13px;
}
</style>
