<template>
  <div class="detail-item">
    <div class="item-label">
      {{label}}
    </div>
    <div class="item-value">
      <div class="value-text">
        {{value || defaultValue}}
      </div>
      <a-button v-if="canEdit" type="primary" @click="$emit('edit')">
        {{ editTips || $t('accountProfiles.edit')}}
      </a-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'InfoItem',
  components: {},
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    defaultValue: {
      type: String,
      default: ''
    },
    /**
     * 是否可以编辑，默认可以编辑
     */
    canEdit: {
      type: Boolean,
      default: true
    },
    /**
     * 编辑按钮提示语
     */
    editTips: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
<style lang='less' scoped>
.detail-item {
    padding: 30px 0;
    font-size: 18px;
    .item-label {
        color: rgba(154, 154, 154, 100);
    }
    .item-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
}
</style>
