<template>
  <!-- <a-modal
    :visible="$attrs"
    title=""
    destroyOnClose
    :okText="okText"
    :okButtonProps="{
        disabled: okDisabled
      }"
    :confirmLoading="loading"
    :closable="false"
    @cancel="handleCancel"
    @ok="handleOk"
    :maskClosable="false"
    :bodyStyle="{'padding': '18px 18px 0 18px'}"
    width="40%"
    > -->
    <BaseModal
      :visible="$attrs"
      :loading="loading"
      :okText="okText"
      :closable="false"
      :okDisabled="okDisabled"
      @cancel="handleCancel"
      :bodyStyle="{'padding': '18px 18px 0 18px'}"
      @ok="handleOk"
      title="">
    <div class="modal-title">{{title}}</div>
    <slot></slot>
  </BaseModal>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import BaseModal from '@/components/core/BaseModal'
export default defineComponent({
  name: 'UserBaseModal',
  components: {
    BaseModal
  },
  props: {
    okDisabled: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })

    const handleCancel = () => {
      context.emit('cancel')
      context.emit('update:visible', false)
    }

    const handleOk = () => {
      context.emit('ok')
    }

    return {
      ...toRefs(state),
      handleCancel,
      handleOk
    }
  }
})
</script>
<style lang='less' scoped>
.modal-title {
  font-size: 20px;
  margin-bottom: 20px;
}
</style>
