<template>
  <UserBaseModal
    :visible="$attrs"
    :okDisabled="false"
    :okText="$t('accountProfiles.sure')"
    :loading="false"
    :title="$t('accountProfiles.emailTips')"
    @cancel="handleCancel"
    @ok="handleOk">
    <a-form
      autocomplete="off"
      has-feedback
      ref="formRef"
      :model="formData"
      :rules="rules"
      name="emailForm">
      <a-form-item
        name="email"
        :validate-status="emailValidateStatus"
        :help="emailValidateTips">
        <a-input
          v-model:value="formData.email"
          :placeholder="$t('accountProfiles.bindEmailTips')" />
      </a-form-item>
      <a-form-item name="verificationCode">
        <a-row style="width: 100%;" :gutter="8">
          <a-col flex="auto">
            <a-input
              style="flex: 1;"
              v-model:value="formData.verificationCode"
              :placeholder="$t('accountProfiles.verifyCode')" />
          </a-col>
          <a-col flex="none">
            <a-button
              ghost
              type="primary"
              style="width: 100%;"
              @click="sendCode"
              v-if="canGetCode">
              {{ $t('accountProfiles.sendVerifyCode') }}
            </a-button>
            <a-button
              v-else
              ghost
              type="primary"
              style="width: 100%;">
              {{timeLeft}} {{$t('forgetPassword.second')}}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </UserBaseModal>
</template>
<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
import UserBaseModal from '@/components/user/modal/UserBaseModal'
import { useI18n } from 'vue-i18n'
import api from '@/services/api'
import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'EmailModal',
  components: {
    UserBaseModal
  },
  props: {},
  setup (props, context) {
    const { t } = useI18n()
    const formRef = ref(null)

    const emailValidate = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error(t('accountProfiles.bindEmailTips')))
      } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) { // 是否满足「邮箱」格式
        return Promise.reject(new Error(t('accountProfiles.emailErrorTips')))
      } else {
        return Promise.resolve()
      }
    }

    const codeValidate = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error(t('forgetPassword.verificationTips')))
      } else {
        return Promise.resolve()
      }
    }

    const state = reactive({
      formData: {
        email: '',
        verificationCode: ''
      },
      canGetCode: true,
      timeLeft: 0,
      rules: {
        email: [{ validator: emailValidate, trigger: 'blur' }],
        verificationCode: [{ validator: codeValidate, trigger: 'blur' }]
      }
    })

    // 邮箱校验状态，用于校验邮箱是否已使用
    const emailValidateStatus = ref()
    // 邮箱校验失败提示语
    const emailValidateTips = ref()

    let intervalId

    /**
     * 开启计时器
     */
    const startTimer = () => {
      if (intervalId) {
        clearInterval(intervalId)
        intervalId = undefined
      }
      state.canGetCode = false
      state.timeLeft = 60
      intervalId = setInterval(() => {
        state.timeLeft -= 1
        if (state.timeLeft <= 0) {
          state.canGetCode = true
          clearInterval(intervalId)
          intervalId = undefined
        }
      }, 1000)
    }

    const handleCancel = () => {
      context.emit('cancel')
      context.emit('update:visible', false)
    }

    /**
     * 通过邮箱验证码绑定邮箱
     * @returns {Promise<void>}
     */
    const handleOk = async () => {
      try {
        await formRef.value.validate()
        const res = await api.bindEmail({ email: state.formData.email, code: state.formData.verificationCode })
        if (res && res.code === 200) {
          message.success(t('accountProfiles.bindEmailSuccess'))
          context.emit('success')
          context.emit('update:visible', false)
        } else {
          if (res.detail) {
            message.error(res.detail)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    /**
     * 获取绑定邮箱验证码
     * @returns {Promise<void>}
     */
    const sendCode = async () => {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(state.formData.email)) {
        return
      }
      const res = await api.getCodeWhenBindEmail({ email: state.formData.email })
      if (res && res.code === 200) {
        emailValidateStatus.value = undefined
        emailValidateTips.value = undefined
        message.success(t('forgetPassword.getVerifyCodeSuccessTips'))
        startTimer()
      } else {
        if (res.detail) {
          emailValidateStatus.value = 'error'
          emailValidateTips.value = res.detail
          message.error(res.detail)
        }
      }
    }

    return {
      ...toRefs(state),
      emailValidateStatus,
      emailValidateTips,
      handleCancel,
      handleOk,
      formRef,
      sendCode
    }
  }
})
</script>
<style lang='less' scoped>

</style>
