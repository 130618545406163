<template>
  <a-modal
    :visible="$attrs"
    :title="title"
    destroyOnClose
    :footer="footer ? undefined : null"
    :okText="okText"
    :okButtonProps="{
        disabled: okDisabled
      }"
    :confirmLoading="loading"
    :class="{'full-modal': breakpoint < breakpointList.lg}"
    :maskClosable="maskClosable"
    :keyboard="false"
    centered
    :closable="closable"
    @cancel="handleCancel"
    @ok="handleOk"
    :bodyStyle="{'padding': '18px 18px 0 18px', 'max-height': breakpoint < breakpointList.lg ? 'max-content' : '80vh', 'overflow-y': 'auto'}"
    :width="breakpoint < breakpointList.lg ? '100%' : '600px'"
    >
    <slot></slot>
  </a-modal>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'BaseModal',
  components: {},
  props: {
    okDisabled: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    footer: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState([
      'breakpoint',
      'breakpointList'
    ])
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })

    const handleCancel = () => {
      context.emit('cancel')
      context.emit('update:visible', false)
    }

    const handleOk = () => {
      context.emit('ok')
    }

    return {
      ...toRefs(state),
      handleCancel,
      handleOk
    }
  }
})
</script>
<style lang='less' scoped>
</style>
<style lang='less'>
.full-modal {
  &&.ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
