<template>
  <UserBaseModal
    :visible="$attrs"
    :okDisabled="false"
    :okText="$t('accountProfiles.sure')"
    :loading="false"
    :title="$t('accountProfiles.changeName')"
    @cancel="handleCancel"
    @ok="handleOk">
    <a-form
      autocomplete="off"
      has-feedback
      ref="formRef"
      :model="formData"
      :rules="rules"
      name="nicknameForm">
      <a-form-item name="nickname">
        <a-input
          v-model:value="formData.nickname"
          :placeholder="$t('accountProfiles.enterName')" />
      </a-form-item>
    </a-form>
  </UserBaseModal>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, ref, watchEffect } from 'vue'
import UserBaseModal from '@/components/user/modal/UserBaseModal'
import { message } from 'ant-design-vue'
import api from '@/services/api'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'NicknameModal',
  components: {
    UserBaseModal
  },
  props: {},
  setup (props, context) {
    const formRef = ref(null)
    const store = useStore()
    const { t } = useI18n()

    const nickNameValidator = async (rule, value) => {
      console.log(rule, value)
      if (!value || value === '') {
        return Promise.reject(new Error(t('accountProfiles.enterName')))
      } else {
        return Promise.resolve()
      }
    }

    const state = reactive({
      formData: {
        nickname: props.defaultNickName
      },
      rules: {
        nickname: [
          { required: true, validator: nickNameValidator, trigger: 'blur' }
        ]
      }
    })
    onMounted(async () => {
    })

    const handleCancel = () => {
      state.formData = {}
      context.emit('cancel')
      context.emit('update:visible', false)
    }

    const handleOk = async () => {
      try {
        await formRef.value.validate()
        const res = await api.user.updateUserInfo({
          nickName: state.formData.nickname
        })
        if (res.code === 200) {
          message.success(t('accountProfiles.editName'))
          context.emit('success')
          context.emit('update:visible', false)
        }
      } catch (error) {
        console.error(error)
      }
    }

    watchEffect(() => {
      if (store.state.user && store.state.user.userInfo && state) {
        state.formData.nickname = store.state.user.userInfo.nickName
      }
    })

    return {
      ...toRefs(state),
      handleCancel,
      handleOk,
      formRef
    }
  }
})
</script>
<style lang='less' scoped>

</style>
