<template>
  <div class="user-center">
    <div class="detail-panel">
      <div class="detail-avatar">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :customRequest="beforeUpload">
          <a-avatar
            :src="checkUserInfo ? checkUserInfo.headAvatar : ''"
            :size="65"
            :style="`background-color: ${checkUserInfo && checkUserInfo.headAvatar ? '' : extractColorByName(checkUserInfo && checkUserInfo.nickName)}`">
            {{checkUserInfo && checkUserInfo.nickName?.substr(0, 1)}}
          </a-avatar>
        </a-upload>
        <div class="avatar-name">
          {{checkUserInfo && checkUserInfo.nickName}}
        </div>
      </div>
      <div class="detail-title">{{$t('accountProfiles.userInfo')}}</div>
      <InfoItem
        :label="$t('accountProfiles.name')"
        :value="checkUserInfo && checkUserInfo.nickName"
        defaultValue=""
        @edit="isNicknameModalShow = true"/>
      <InfoItem
        :label="$t('accountProfiles.email')"
        :value="checkUserInfo && checkUserInfo.email"
        :defaultValue="$t('accountProfiles.unbind')"
        :can-edit="!checkUserInfo || !checkUserInfo.email"
        :editTips="$t('accountProfiles.bind')"
        @edit="isEmailModalShow = true"/>
      <InfoItem
        :label="$t('accountProfiles.phone')"
        :value="checkUserInfo && checkUserInfo.phone"
        :defaultValue="$t('accountProfiles.unbind')"
        :can-edit="!checkUserInfo || !checkUserInfo.phone"
        :editTips="$t('accountProfiles.bind')"
        @edit="isPhoneModalShow = true" />
    </div>
    <div class="detail-panel">
      <div class="detail-title">{{$t('accountProfiles.passSafe')}}</div>
      <InfoItem
        :label="$t('accountProfiles.pass')"
        value="******"
        @edit="isPassModalShow = true"/>
    </div>
  </div>
  <NicknameModal
    v-model:visible="isNicknameModalShow"
    @success="updateInfoSuccess"/>
  <EmailModal v-model:visible="isEmailModalShow" @success="updateInfoSuccess"/>
  <PhoneModal v-model:visible="isPhoneModalShow" @success="updateInfoSuccess"/>
  <PasswordModal v-model:visible="isPassModalShow" />
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { extractColorByName } from '@/utils/utils'
import InfoItem from '@/components/user/input/InfoItem'
import NicknameModal from '@/components/user/modal/NicknameModal'
import EmailModal from '@/components/user/modal/EmailModal'
import PhoneModal from '@/components/user/modal/PhoneModal'
import PasswordModal from '@/components/user/modal/PasswordModal'
import { message, Upload } from 'ant-design-vue'
import SparkMD5 from 'spark-md5'
import api from '@/services/api'
import config from '@/config'
import { USERS } from '@/store/type'
export default defineComponent({
  name: 'UserCenter',
  components: {
    InfoItem,
    NicknameModal,
    EmailModal,
    PhoneModal,
    PasswordModal
  },
  props: {},
  setup (props, context) {
    const store = useStore()

    const state = reactive({
      isNicknameModalShow: false,
      isEmailModalShow: false,
      isPhoneModalShow: false,
      isPassModalShow: false
    })
    onMounted(async () => {

    })

    const checkUserInfo = computed(() => {
      return store.state.user && store.state.user.userInfo
    })

    const beforeUpload = async (option) => {
      if (!(/^image\/[jpeg|png|jpg]/.test(option.file.type))) {
        message.error('当前格式不支持上传')
        return Upload.LIST_IGNORE
      }

      try {
        const path = await conteactPhoto(option.file)
        const res = await api.user.updateUserInfo({
          headAvatar: path
        })
        if (res.code === 200) {
          updateInfoSuccess()
          message.success('修改头像成功')
        }
      } catch (error) {
        console.error(error)
      }

      // return new Promise((resolve) => {
      //   // console.log(file)
      //   filetoBase64(file, (url) => {
      //     console.log(url)
      //     // state.fileList.push({
      //     //   file: file,
      //     //   ...file,
      //     //   thumbUrl: url
      //     // })
      //     resolve()
      //   })
      // })
    }

    const conteactPhoto = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        const spark = new SparkMD5() // 创建md5对象（基于SparkMD5）
        if (file.size > 1024 * 1024 * 10) {
          const data1 = file.slice(0, 1024 * 1024 * 10) // 将文件进行分块 file.slice(start,length)
          fileReader.readAsBinaryString(data1) // 将文件读取为二进制码
        } else {
          fileReader.readAsBinaryString(file)
        }

        // 文件读取完毕之后的处理
        // a639e28526d1809745b46bf1189594fe  6d9efe0c593b1383482feb229318e03a
        fileReader.onload = async (e) => {
          spark.appendBinary(e.target.result)
          const md5 = spark.end()
          console.log(md5, file)
          const list = file.name.split('.')
          const res = await upload(list[list.length - 1]?.toUpperCase(), md5, file)
          if (res) {
            resolve(res)
          } else {
            reject(new Error('false'))
          }
        }
      })
    }

    // 获取oss url
    const upload = async (type, hash, file) => {
      try {
        const res = await api.moment.getWatermarkOssUrl({
          type,
          hash
        })
        if (res.code === 200) {
          const data = res.data
          const result = await fetch(data.uploadUrl, {
            headers: data.headers,
            method: data.method,
            body: file
          })
          if (result.status === 200) {
            return config.PHOTO_APP_IMG_BASE_URL + data.path
            // state.upHash = hash
            // state.fileList = [{
            //   hash: hash,
            //   aliasName: file.name,
            //   path: config.IMG_BASE_URL + state.ossObj.path,
            //   file
            // }]
          }
        } else if (res.code === 20005) {
          // filetoBase64(file, (url) => {
          //   state.fileList = [{
          //     hash: hash,
          //     aliasName: file.name,
          //     path: url,
          //     file
          //   }]
          // })
        } else if (res.code === 400) {
          message.error('当前格式不支持上传')
        }
      } catch (error) {
        console.error(error)
      }
    }

    const updateInfoSuccess = () => {
      store.dispatch('user/' + USERS.SET_USER_INFO)
    }

    return {
      ...toRefs(state),
      extractColorByName,
      checkUserInfo,
      beforeUpload,
      updateInfoSuccess
    }
  }
})
</script>
<style lang='less' scoped>
.user-center {
  max-width: 800px;
  margin: 10px auto 20px;
  font-weight: 600;
  .detail-panel {
    background: white;
    padding: 20px 30px 0;
    /* text-align: center; */
    border-radius: 10px;
    .detail-avatar {
      text-align: center;
    }
    .avatar-name {
      margin-top: 9px;
      font-size: 18px;
    }
    .detail-title {
      font-size: 20px;
      margin-top: 15px;
    }
    .detail-item + .detail-item {
      border-top: solid 1px rgba(0,0,0,0.03);
    }
  }
  .detail-panel + .detail-panel {
    margin-top: 32px;
  }
  .avatar-uploader {
    border-radius: 50%;
  }
}
</style>
